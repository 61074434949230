/*
These functions make sure WordPress
and Foundation play nice together.
*/

jQuery(document).ready(function($) {
	
    // Remove empty P tags created by WP inside of Accordion and Orbit
    $('.accordion p:empty, .orbit p:empty').remove();

	// Adds Flex Video to YouTube and Vimeo Embeds
	$('iframe[src*="youtube.com"], iframe[src*="vimeo.com"]').each(function() {
		if ( $(this).innerWidth() / $(this).innerHeight() > 1.5 ) {
		  $(this).wrap("<div class='widescreen responsive-embed'/>");
		} else {
		  $(this).wrap("<div class='responsive-embed'/>");
		}
	});

	$('.product-categories li.current-cat > a').addClass('active').next('.children').addClass('show');
	$('.product-categories li.current-cat-ancestor > a').addClass('active').next('.children').addClass('show');

	$('.product-categories li').each(function(){
		var children = $(this).children('a').next('.children');

		if(children.length !== 0) {
	    	$(this).addClass('has-children');
	    }
	});

	$('.product-categories a').click(function(e) {
	    var $this = $(this);

	    if($this.next('.children').length !== 0) {
	    	e.preventDefault();
	    }
	  
	    if ($this.next().hasClass('show')) {
	    	$this.parent().parent().find('.active').removeClass('active');
	        $this.next().removeClass('show');
	        $this.next().slideUp(350);
	    } else {
	        $this.parent().parent().find('li .children').removeClass('show');
	        $this.parent().parent().find('li .children').slideUp(350);
	        $this.addClass('active');
	        $this.next().toggleClass('show');
	        $this.next().slideToggle(350);
	    }
	});

	$('.hide-show-filters .inner-container').click(function() {
		var productListing = $('.product-listing .medium-16'),
			productListingFilter = $('.product-listing .medium-8'),
			productListingFilterWidth = productListingFilter.outerWidth(),
			$windowWidth = $(window).width();

		if( productListing.hasClass('moved') ) {
			$(this).find('.inner-text').text('HIDE FILTERS');
			productListing.removeClass('moved');
			productListingFilter.animate({
				opacity: 1
			}, 800);
			productListingFilter.removeClass('sidebar-hidden');

			if( $windowWidth > 735 ) {
				productListing.animate({
					left: '0'
				}, 600);
		    }
		} else {
			$(this).find('.inner-text').text('SHOW FILTERS');
			productListing.addClass('moved');
			productListingFilter.animate({
				opacity: 0
			}, 600);
			if( $windowWidth > 735 ) {
				productListing.animate({
					left: -productListingFilterWidth
				}, 800, function() {
					productListingFilter.addClass('sidebar-hidden')
				});
			} else {
				productListingFilter.addClass('sidebar-hidden');
			}
		}
	});

	$('.tabs-anchor a').on('click', function(event) {
		if (this.hash !== "") {
			event.preventDefault();

			var hash = this.hash;

			$('html, body').animate({
				scrollTop: $(hash).offset().top
			}, 800, function(){
				window.location.hash = hash;
			});
		}
	});
}); 

jQuery(window).load(function(){
	jQuery.fn.matchHeight._update()
});



